.js-popout {
  @apply fixed min-h-screen overflow-y-scroll h-full top-0 w-full;
  right: 0;
  z-index: 900;
  transform: translateX(100vw);
  transition: transform 0.4s 0.4s ease;
  width: 100vw;
  @screen sm {
    width: 50vw;
    transform: translateX(50vw);
  }
  &.active {
    transform: translateX(0);
  }
}

.slideout-open {
  overflow-y: hidden;
}

// .nav-is-open {
//   .js-backdrop {
//     opacity: 0 !important;
//     pointer-events: none;
//     -webkit-animation: fade-out 0.3s ease both !important;
//     animation: fade-out 0.3s ease both !important;
//   }
// } 
 
.js-backdrop {
  background: rgba(black, 0.5);
  z-index: 30; 
  transition: opacity 0.4s ease;
  @apply fixed w-full h-full opacity-60 top-0 left-0;
  &.close {
    opacity: 0;
    pointer-events:none;
  }
}

.js__spinner {
  height: 25px;
  width: 25px;
  border: 1px solid black;
  border-right: 1px solid transparent;
  border-radius: 50%;
}
.js-notification {
  z-index: 100;
  button svg path {
    stroke: currentColor;
  }
}
.js-notification--bar {
  z-index: 60;
  transition: all 0.2s ease;
  display: none;

  a {
    text-decoration: underline;
  }
}

[v-cloak] {
  display: none;
}

.gscrollbar-fixer {
  position: relative;
  padding-right: 17px !important;
  margin-right: 0 !important;
  // #main-nav{
  // 	padding-right: 12px;
  // }
}
