select {
	background: transparent;
	cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type='number'] {
	-moz-appearance: textfield;
}

.form-row {
	display: flex;
	flex-wrap: wrap;
	@screen md {
		.form-group {
			padding-left: 0;
			padding-right: 20px;
			&:nth-of-type(2n) {
				padding-left: 20px;
				padding-right: 0;
				label {
					left: 20px;
				}
			}
		}
	}
}

.form-group {
	@apply relative;

	label {
		@extend .body-copy;
		@screen xxl {
			@apply text-lead;
		}
		cursor: pointer;
		top: 0;
		position: absolute;
		transform: scale(0.8);
	}

	input {
		@extend .body-copy;
		@screen xxl {
			@apply text-lead;
		}
	}
}

.form-group--select {
	select {
		@apply border-b-1 border-black;
		width: 100%;
	}
}

#search-bar {
	input[type='search']::-webkit-search-cancel-button {
		position: relative;
		right: 20px;
		-webkit-appearance: none;
		height: 20px;
		width: 20px;
		background: url('../icons/white-close.svg');
		background-size: cover;
		opacity: 1;
		transition: all 0.2s ease;
		&:hover {
			opacity: 0.7;
		}
	}
}

.form-group--styled {
	@apply relative w-full py-10;

	label {
		@apply absolute transform left-0 text-body text-white opacity-100;
		transform: translateY(27px);  
		pointer-events: none;
		transition: all 0.4s ease;
		transform-origin: top left;
		@screen xxl {
			@apply text-lead;
		}
	}
	input,
	textarea,
	select {
		@apply bg-transparent w-full text-body px-0 py-10 border-b-1 border-white text-white;
		@screen xxl {
			@apply text-lead;
		}
		border-radius: 0;
	}
	select {
		option {
			@apply text-black;
		}
	}
	textarea {
		height: 35px;
		resize: vertical;
	}
	&.active,
	&[data-has-value='true'] {
		label {
			transform: translateY(-5px) scale(0.8);
			opacity: 1;
		}
	}
}

.form-group--black {

	label {
		@apply text-black opacity-100;
		
	}
	input,
	textarea,
	select {
		@apply border-black text-black;
	}
	select {
		option {
			@apply text-black;
		}
	}
}

.form-group--inline {
	.form-group__field {
		@apply bg-transparent p-15 border-1 border-white;
		border-right: 1px solid transparent;
		@include placeholder() {
			@apply text-white;
		}
	}
	.form-group__append {
		@apply bg-transparent p-15 border-1 border-white transition-colors  duration-200;
		border-left: 1px solid transparent;
		margin-left: -2px;

		&:hover {
			@apply bg-white text-white border-white;
		}
	}
}

.label--small,
.form-group--styled .label--small {
	transform: scale(0.8);
	transform-origin: left;
	display: block;
}

.select-box {
	display: flex;
	flex-direction: column;
}

.select-box .options-container {
	background-color: #fff;
	color: black;
	max-height: 0;
	width: 100%;
	opacity: 0;
	transition: all 0.4s;
	overflow: hidden;
	position: absolute;
	order: 1;
	top: 35px;
	left: 0;
	z-index: 20;
}

.selected-option {
	background: transparent;
	color: black;
	position: relative;

	order: 0;
	&:hover {
		opacity: 0.75;
	}
}

.selected-option::after {
	content: '';
	// background: url("https://assets/icons/chevron-down.svg");
	background-size: 12px 12px;
	background-repeat: no-repeat;

	position: absolute;
	height: 100%;
	width: 12px;
	right: 0px;
	top: 5px;
	transition: all 0.4s;
}

.select-box .options-container.active {
	max-height: 240px;
	opacity: 1;
	overflow-y: scroll;
}

.select-box .options-container.active + .selected::after {
	transform: rotateX(180deg);
	top: -6px;
}

.select-box .option,
.selected {
	padding: 5px 10px;
	cursor: pointer;
}

.select-box .option:hover {
	@apply bg-gray-200;
}

.select-box label {
	cursor: pointer;
}

.select-box .option .radio {
	display: none;
}

.formulate-input-errors {
	@apply text-left text-magenta;
	li {
		@apply text-small text-left;
	} 
	.bg-red & {
		@apply text-magenta;
		li {
			@apply text-magenta;
		}
	}
}
.form-group--formulate {
	margin-bottom: 10px;
	.form-group--styled {
		margin-bottom: 5px;
	}
}
.StripeElement {
	border-bottom: 1px solid #fff;
}
.ElementsApp,
.ElementsApp .InputElement {
	padding: 10px;
}

.stripe-expiry {
	width: 100px;
}
.stripe-cvc {
	width: 50px;
}



.form-group--file {
    overflow: hidden;
    position: relative;
    padding: 0;

	.custom-file{

		.custom-file-input{
			height: 100%;
		    width: 100%;
		    padding: 7px 30px;
		    opacity: 0;
		    position: relative;
		    z-index: 10;
		}

		&:after {
		    content: "Upload application form";
		    position: absolute;
		    width: 100%;
		    left: 0;
		    top: 0;
		    height: 100%;
		    display: flex;
		    align-items: center;
		    justify-content: center;
		}
	}


}


.register-form,.submit-application{ 
	&.form-submitted{
		.form-container{
			display: none;
		}
	}
}