.btn {
  @apply
	px-30 
	py-10
	text-center 
	font-aeonikregular
	duration-200 
	transition-colors 
	inline-flex 
	items-center 
	justify-center
	rounded-full;
  max-width: 280px;
  width: 100%;
  font-size: 16px; 
  padding: 8px 10px;
  @screen md {
    @apply px-30 py-10;
  }

  &:hover,
  &:focus {
    outline: 0;
    box-shadow: none;
  }

  &[disabled="true"],
  &[disabled="true"]:hover,
  &[disabled="true"]:focus {
    opacity: 0.5 !important;
  }
  &.loading {
    color: transparent !important;
    position: relative;
    &:after {
      content: "";
      opacity: 1;
      width: 20px; 
      height: 20px;
      border-radius: 50%;
      border: 2px white solid;
      border-right: 2px transparent solid !important;
      animation: spin-centered 2s infinite both linear;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
    }
  }
  &[disabled="true"],
  &[disabled="true"]:hover,
  &[disabled="true"]:focus {
    @apply opacity-50;
  }
}

.btn--primary {
  @apply bg-black text-white border-white border-1;
  &:hover,
  &.loading,
  &.active {
    @apply text-black bg-white border-black;
    &:after {
      @apply border-black;
    }
  }
}

.btn--grey {
  @apply bg-grey text-black border-grey border-1 px-30 py-10;
  &:hover,
  &.loading,
  &.active {
    @apply text-grey bg-black;
    &:after {
      @apply border-black;
    }
  }
}

.btn--red {
  @apply bg-magenta text-white border-magenta border-1 px-30 py-10;
  &:hover,
  &.loading,
  &.active {
    @apply text-white bg-black;
    &:after {
      @apply border-black;
    }
  }
}

.btn--magenta {
  @apply bg-magenta text-white border-magenta border-1 px-30 py-10;
  &:hover,
  &.loading,
  &.active {
    @apply text-white bg-black;
    &:after {
      @apply border-black;
    }
  }
}

.btn--inverse {
  @apply text-black bg-white border-black border-1;
  &:hover,
  &.loading,
  &.active {
    @apply bg-white text-black;
    &:after {
      @apply border-black;
    }
  }
  .bg-white & {
    @apply bg-white text-black border-white border-1;
    &:hover,
    &.loading,
    &.active {
      @apply text-black bg-white;

      &:after {
        @apply border-black;
      }
    }
  }
}

.btn--white {
  @apply bg-white text-black border-white border-1;
  &:hover,
  &.loading,
  &.active {
    @apply text-white bg-black;
  }
}

.btn--outline {
  @apply bg-transparent text-black border-black border-1;
  &:hover,
  &.loading,
  &.active {
    @apply text-white bg-black;
    &:after {
      @apply border-black;
    }
  }
  &.white-outline {
    @apply text-white border-white border-1;
    &:hover,
    &.loading,
    &.active {
      @apply text-black bg-white;
    }
  }
}
