@import "glightbox/dist/css/glightbox";
@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";
//Type
@import "typography/font-loading";
@import "typography/typography";

// helpers
@import "utilities/mixins";
@import "utilities/animation";
@import "utilities/grid";
@import "utilities/buttons";
@import "utilities/globals";

@import "components/hero";
@import "components/form";
@import "components/nav";
@import "components/footer";
@import "components/slider";
@import "components/glideinit";
@import "components/modal";
@import "components/js-classes";
@import "components/expand-collapse";

@import "pages/investments";

.formulate-file-name {
  @extend .body-text;
  margin-top: 10px;
}
.grecaptcha-badge {
  visibility: hidden;
}
.recaptcha-blurb {
  @extend .body-text;
  @apply w-full mt-sm;
  font-size: 11px;
  color: inherit;
  line-height: 1.1em;
}
.step-0 {
  @media (max-width: 768) {
    @apply flex flex-col;
  }
}
.step-zero-half {
  min-height: calc(var(--vh, 1vh) * 50);
  @supports (-webkit-touch-callout: none) {
    min-height: calc(50% - 50px);
  }
  @screen sm {
    min-height: 50vh;
  }
}
