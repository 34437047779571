
.expand-collapse__expander,
.js-dropdown__container{
	&[aria-expanded='false']{
		display: none;
	}
}




.expand-collapse__toggle{
	// .icon{
	// 	opacity: 0;
	// }

	&:hover{
		.icon{
			opacity: 1;
		}
	}
}
