.container {
  @apply mx-auto px-15;

  width: 100%;

  @screen sm {
    @apply w-sm-screen;
  }

  @screen md {
    @apply w-md-screen px-15;
  }
  @screen lg {
    @apply w-lg-screen;
  }
  @screen xl {
    @apply w-xl-screen;
  }


  &--fluid {
    @apply mx-auto px-15;
    width: 100%;
  }
}

@screen md {
  .md\:container {
    @apply mx-auto #{!important};
    @screen md {
      @apply w-md-screen px-sm;
    }
    @screen lg {
      @apply w-lg-screen;
    }
  }
}

.container-fluid {
  @apply w-full px-10;
  @screen md {
      @apply px-15;
    }
}

.row {
  @apply flex flex-wrap -mx-15;

  @screen md {
    @apply -mx-15;
  }
  &--fluid {
    @extend .row;
    margin: 0;
  }
}

.row--narrow {
  margin: 0 -12px;
}

@responsive {
  .col {
    @apply px-10;
    @screen md {
      @apply px-15;
    }
    &.col--narrow {
      @apply px-12;
    }
  }
}

@screen md {
  .col {
    @apply px-15;

    @screen lg {
      // @apply px-sm;
    }
  }
}


@responsive {
	.offset-0 {
		margin-left: 0;
	}
	.offset-1\/2 {
		margin-left: 50%;
	}
	.offset-1\/3 {
		margin-left: 33.3333333333%;
	}
	.offset-2\/3 {
		margin-left: 66.6666666666%;
	}
	.offset-1\/4 {
		margin-left: 25%;
	}
	.offset-3\/4 {
		margin-left: 75%;
	}
	.offset-1\/6 {
		margin-left: 16.6666666667%;
	}
	.offset-1\/12 {
		margin-left: 8.3333333333%;
	}
	.offset-2\/12 {
		margin-left: 16.666666666%;
	}
	.offset-3\/12 {
		margin-left: 24.999999999%;
	}
	.offset-5\/12 {
		margin-left: 41.6666666667%;
	}
	.offset-7\/12 {
		margin-left: 58.3333333333%;
	}
	.offset-11\/12 {
		margin-left: 91.6666666667%;
	}


	.offset-1\/16 {
		margin-left: 6.25%; 
	}
	.offset-2\/16 {
		margin-left: 12.5%;
	}
	.offset-3\/16 {
		margin-left: 18.75%;
	}
	.offset-4\/16 {
		margin-left: 25%;
	}
	.offset-5\/16 {
		margin-left: 31.25%;
	}
	.offset-6\/16 {
		margin-left: 37.5%;
	}
	.offset-7\/16 {
		margin-left: 43.75%;
	}
	.offset-8\/16 {
		margin-left: 50%;
	}
	.offset-9\/16 {
		margin-left: 56.25%;
	}
	.offset-10\/16 {
		margin-left: 62.5%;
	}
	.offset-11\/16 {
		margin-left: 68.75%;
	}
	.offset-12\/16 {
		margin-left: 75%;
	}
	.offset-13\/16 {
		margin-left: 81.25%;
	}
	.offset-14\/16 {
		margin-left: 87.5%;
	}
	.offset-15\/16 {
		margin-left: 93.75%;
	}
}
