
.glide--fade{
	.glide__slides {transform:none !important; width:100% !important; display:block;}
	.glide__slide {position:absolute; left:0; top:0; opacity:0; width:100% !important; transition:opacity 1s;}
	.glide__slide:first-child {position:relative;}
	.glide__slide--active {z-index:1; opacity:1;}
}


.glide--hero{
  &:after{
    content: "";
    height: 200px;
    width: 100%;
    transform: rotate(-180deg); 
    background-image: linear-gradient(180deg, rgba(4,2,25,0.50) 0%, rgba(4,2,25,0.00) 100%);
  }
}


.glide--list{

}

.glide__bullets{
	.glide__bullet{
		.glide__bullet--icon{
			@apply opacity-0 transition-all duration-300;
			position: absolute;
			padding-left: 0;
		}
		&.glide__bullet--active{
			.glide__bullet--icon{
				@apply opacity-100;
			}
			.glide__bullet--title{
				padding-left: 18px;
			}
		}
	}
}

.glide--images{
  .glide__bullets {
    @apply w-full; 
    position: absolute;
    text-align: right;
    transition: .3s opacity;
    z-index: 10;
    bottom: 10px;
    right: 50px;

    .glide__bullet {
        @apply py-15 mx-5; 
        background: transparent;
        border-radius: 0;
        width: 32px;
        height: 2px;

        &:before{
          @apply h-full w-full block; 
          background: #9A99A6;
          width: 32px;
          height: 2px; 
          content: "";
        }

      &.glide__bullet--active {
          &:before{
            background-color: #ffffff;
          }
        }
    }
  } 
}


.glide--hero{
  .glide__bullets {
    @apply w-full; 
    position: absolute;
    text-align: right;
    transition: .3s opacity;
    z-index: 10;
    bottom: -30px;
    right: 0;

    .glide__bullet {
      	@apply py-15 mx-5; 
      	background: transparent;
      	border-radius: 0;
      	width: 32px;
      	height: 2px;

      	&:before{
        	@apply h-full w-full block; 
        	background: #9A99A6;
        	width: 32px;
        	height: 2px; 
        	content: "";
      	}

     	&.glide__bullet--active {
	        &:before{
	          background-color: #020220;
	        }
      	}
    }
  } 
}
