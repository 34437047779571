


#main-hero{
	&.tereo-active{ 
		.main-hero__english{
			transition: opacity 1s 0.4s ease;
			opacity: 0; 
		}
		.main-hero__tereo{
			transition: opacity 1s 0.4s ease;
			opacity: 1;
		}

	}
	.main-hero__english{
		transition: opacity 1s 0.4s ease;
		opacity: 1; 
	}
	.main-hero__tereo{
		transition: opacity 1s 0.4s ease;
		opacity: 0;
	}
} 



