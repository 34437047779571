#web {
  overflow: hidden scroll;
}

.nav-white {
  .logo {
    svg {
      * {
        fill: #ffffff;
      }
    }
  }

  .nav-sticky {
    @screen lg {
      svg path {
        fill: #000000;
      }
    }
  }

  .btn--outline {
    @apply bg-transparent text-white border-white border-1;
    &:hover,
    &.loading,
    &.active {
      @apply text-black bg-white;
    }
  }

  .nav-trigger {
    &::after,
    &::before {
      background: #ffffff;
    }
  }
}

.link__images {
  .link__images--hover {
    @apply transition-all duration-300;
    opacity: 0;
    z-index: 4;
    &.default-img {
      z-index: 1 !important;
    }
    &.active {
      opacity: 1;
      z-index: 4;
    }
    &.active-page {
      opacity: 1;
      z-index: 2;
    }
  }
}

.nav__link {
  @apply text-16 mx-10 relative font-medium;
  line-height: 100%;
}



.logo {
  svg {
    width: 150px;
    height: 50px;
    @screen lg {
      width: 200px;
      height: 60px;
    }
  }
}

.icon-cart {
  path {
    @apply fill-current;
  }
}

.nav-trigger {
  width: 50px;
  height: 10px;
  position: relative;
  @apply transition-opacity duration-300;

  &:focus {
    outline: 0;
    box-shadow: none;
  }

  &::after,
  &::before {
    content: "";
    width: 30px;
    height: 2px;
    display: block;
    background: #000000;
    position: absolute;
    left: 0;
    transition: all 0.4s ease;
    transform: rotate(0deg) transform(0, 0);
  }

  &::after {
    top: 0;
    transform-origin: center;
  }
  &::before {
    top: 10px;
    transform-origin: center;
  }
}

#main-nav.bg-white,
.nav-sticky,
#main-nav.black {
  .nav-trigger {
    &:after,
    &:before {
      @apply bg-offBlack;
    }
  }
}

.nav-trigger {
  &::before {
    transition: transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19), background ease 0.15s;
  }

  &::after {
    transition: transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19), background ease 0.15s;
  }

  &.is-active {
    &::before {
      top: 15px;
      transform: rotate(-45deg);
      transition: transform 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      background: white;
      
    }

    &::after {
      top: 15px;
      transform: rotate(45deg);
      transform-origin: center;
      transition: transform 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      background: white;
      
    }
  }
}

#main-nav {
  #applynow {
    padding: 8px 10px;
    font-size: 12px; 
    @screen sm {
      font-size: 1.6rem;
      padding: 10px 30px; 
    }
  }
  .nav-backdrop {
    pointer-events: none;
    background: rgba(black, 0.5);
    z-index: 8;
    @apply fixed w-full h-full top-0 left-0;
    opacity: 0;
    transition: all 0.4s ease;
  }

  .main-nav__bar {
    @apply px-10 py-30 bg-black;
    position: fixed;
    top: 0;
    right: 0;
    flex-direction: column;
    width: 100vw;
    min-height: 100vh;
    height: 100%;
    transition: all 0.4s ease;
    transform: translateX(100vw);
    z-index: 10;
    overflow-y: scroll;
    overflow-x: hidden;
    @screen sm {
      @apply p-30 bg-black;
      transform: translateX(50vw);
      width: 50vw;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
    }
  }
  & .nav__link {
    line-height: 0.95em;
      margin-right: 50px;
    &:after {
      background: #fff;
    }
  }

  &.is-open {
    .nav-backdrop {
      pointer-events: unset;
      opacity: 0.6;
    }

    .main-nav__bar {
      right: 0;
      transform: translateX(0vw);
      transition: all 0.4s ease;
    }

    .nav-trigger {
      &:after,
      &:before {
        @apply bg-white;
      }
    }
  }
  &.is-animating {
    .main-nav__bar {
      display: flex;
      visibility: visible;
    }
  }
  .nav__link {
  }
}

#main-nav {
  transition: all 0.3s ease-out;
}

.nav-sticky {
  @apply fixed text-offBlack py-10 border-b-1  #{!important};
  @screen lg {
    .nav__link {
      &:after {
        @apply bg-offBlack;
      }
    }
  }
  svg {
    fill: black;
  }
  .js-notification--bar + #swup & {
    margin-top: 0;
  }

  &.nav-white {
    @apply bg-white;
    .btn--outline {
      background-color: transparent;
      --text-opacity: 1;
      color: #000000;
      color: rgba(0, 0, 0, var(--text-opacity));
      --border-opacity: 1;
      border-color: #000000;
      border-color: rgba(0, 0, 0, var(--border-opacity));
      border-width: 1px;
    }
    .logo svg * {
      fill: #000 !important;
    }
  }
}

.nav-sticky--show {
  // transform:translateY(0) ;
  transition: all 0.3s ease-out;
}
.nav-sticky--hide {
  // transform:translateY(-100px);
}
