
.heroSwiper{
  .swiper-pagination {
    @apply w-full; 
    position: absolute;
    text-align: right;
    transition: .3s opacity;
    z-index: 10;
    bottom: 0;
    right: 50px;

    .swiper-pagination-bullet {
      @apply py-15 mx-5; 
      background: transparent;
      border-radius: 0;
      width: 32px;
      height: 2px;

      &:before{
        @apply h-full w-full block; 
        background: #9A99A6;
        width: 32px;
        height: 2px; 
        content: "";
      }

      &.swiper-pagination-bullet-active {
        &:before{
          background-color: #ffffff;
        }
      }
    }
  } 
}


.swiper {
  width: 100%;
  height: 100%;
}


.swiper-slide {
  background-position: center;
  background-size: cover;
}


