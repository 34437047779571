* {
  -webkit-tap-highlight-color: transparent;
  overflow-anchor: none;
}

*:focus {
  outline: none;
}

*::selection {
  background: black;
  color: #fff;
}

body,
html {
  @apply font-aeonikregular font-normal;
  width: 100%;
  font-size: 10px;

  &.scrolling {
    scroll-behavior: smooth;
  }
}

button {
  &:focus {
    box-shadow: 0;
    outline: 0 !important;
  }
}

[data-src] {
  transition: all 0.3s ease;
  transform-origin: center;
}
.lazyload,
.lazyloading {
  filter: blur(100px);
}
.lazyloaded {
  filter: blur(0px);
}
@responsive {
  .center-y {
    @include center("y");
  }
  .center-x {
    @include center("x");
  }
  .center {
    @include center();
  }
}

.play-button {
  cursor: pointer;
  &:hover,
  a:hover & {
    opacity: 0.6;
  }
}


.inline-video__placeholder {
  animation: fade 0.3s ease-out both; 

  &.hide {
    animation: fade-out 0.3s ease-out both;
  }

  	@apply duration-200 transition-colors;  
	
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.parallax-section {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @screen md {
    background-attachment: fixed;
  }
}

.list-hover {
  @apply relative opacity-100 transition-all duration-300;
  padding-left: 0;
  &:before {
    @apply opacity-0 transition-all duration-300;
    content: "—";
    left: 0;
    position: absolute;
  }
  &:hover,
  &.active-hover {
    padding-left: 18px;
    &:before {
      @apply opacity-100;
    }
  }
}

.bullet-list {
  display: block;
  list-style-type: disc;
  padding-left: 20px;
  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }
}

.flex-table {
  display: flex;
  flex-direction: column;
  border-collapse: collapse;
  thead {
    display: none;
  }
  tr {
    @apply flex flex-col border-b-1 border-black pt-15;
  }
  th {
    @apply text-small font-normal;
    &:first-of-type {
      @apply pl-0;
    }
    &:last-of-type {
      @apply pr-0;
    }
  }
  td {
    @apply flex-col py-5 justify-start;
    &:first-of-type {
      @apply pl-0;
    }
    &:last-of-type {
      @apply pr-0;
    }
  }
  th,
  td {
    flex: 1;
    &[colspan="0"] {
      flex: 0;
    }
    &[colspan="2"] {
      flex: 2;
    }
    &[colspan="3"] {
      flex: 3;
    }
    &[colspan="4"] {
      flex: 4;
    }
  }
  @screen md {
    thead {
      @apply w-full text-small block;
    }
    tr {
      @apply flex-row pt-0;
    }
    th,
    td {
      @apply flex px-xs py-xs;
      min-width: 120px;
    }
  }
}

.h-screen{
  height: calc(var(--vh, 1vh) * 100) !important;
}

.slideout-open {
  overflow: hidden;
}

.swiper--fade {
  @apply h-full;
  .swiper-container {
    @apply h-full;
  }
  .swiper-slide {
    height: auto;
  }
  .swiper-pagination-bullet {
    height: 2px;
    width: 22px;
    border-radius: 0;
    background-color: white;
  }
}

.contact-grid {
  @screen md {
    grid-template-rows: max-content min-content;
  }
  .markdown {
    a {
      text-decoration: none;
    } 
  } 
}

.box-shadow {
  box-shadow: 0px 7px 24px rgba(0, 0, 0, 0.09);
}
