* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply font-aeonikregular font-normal;
}

p {
  @apply font-aeonikregular font-normal text-body leading-standard;
  a {
    text-decoration: underline;
    opacity: 1;
    transition: opacity 0.2s ease;
    &:hover {
      opacity: 0.7;
    }
  }
}
em {
  font-size: inherit;
}
.heading {
  @apply font-aeonikregular uppercase leading-standard;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  @apply font-aeonikbold;
}
.h0 {
  @apply text-mob-h1  leading-standard font-aeonikbold;
  @screen md {
    @apply text-h1;
  }
  @screen lg {
    @apply text-h0;
  }
}
h1,
.h1 {
  @apply font-aeonikbold font-medium text-mob-h1 leading-30;
  @screen sm {
    @apply text-tablet-h1 leading-50;
  }
  @screen md {
    @apply text-h1;
    line-height: 60px;
  }
}
h2,
.h2 {
  @apply font-aeonikbold font-medium text-mob-h2 leading-25;
  @screen sm {
    @apply text-tablet-h2 leading-30;
  }
  @screen md {
    @apply text-h2;
    line-height: 38px;
  }
}
h3,
.h3 {
  @apply font-aeonikbold font-medium text-mob-h3 leading-32;
  @screen md {
    @apply text-h3 leading-24;
  }
}

h4,
.h4 {
  @apply font-aeonikbold font-medium text-mob-h4;
  line-height: 130%;
  @screen md {
    @apply text-16 leading-18;
  }
}

.body-copy,
.body-text {
  @apply font-aeonikregular font-normal text-16;
  line-height: 130%; 
  @screen md {
    @apply text-body leading-24;
  }
}

.lead-text {
  @apply font-aeonikregular font-normal text-16 leading-22;
 
  @screen md {
    @apply text-lead leading-28;
  }
}

.caption-text {
  @apply font-aeonikregular font-normal text-tiny leading-16 uppercase;
}

.label-text {
  @apply font-aeonikregular font-normal text-tiny leading-16 uppercase;
  letter-spacing: 1px;
}

.small-text {
  line-height: 130%; 
  @apply font-aeonikregular font-normal text-small;
}

dd {
  @apply mb-sm;
  &.last {
    @apply -mt-sm;
  }
}

.link {
  @apply text-body underline;

  &--styled-underline {
    @apply no-underline text-body relative transition-opacity duration-200;
    padding-bottom: 5px;

    &::after {
      content: "";
      height: 1px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      @apply bg-bg;
    }
    &.text-white {
      &::after {
        @apply bg-white;
      }
    }
    &:hover {
      opacity: 0.7;
    }
  }
}

.markdown {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mb-30;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply;
  }
  h4 {
    font-size: 30px;
  }
  p {
    @apply mb-sm;
    &:last-of-type {
      @apply mb-rg;
    }
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }

  p,
  li {
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }

  a {
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
    transition: opacity 0.2s ease;
    @apply underline;
    text-decoration: underline;
    &.no-underline {
      @apply no-underline;
    }
    &:hover {
      opacity: 0.7;
    }
  }
  ol:not(.styled-list),
  ul:not(.styled-list) {
    @apply pl-sm mb-sm;
    list-style: revert;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }
  &--reduced-spacing {
    li,
    p {
      &:last-of-type {
        @apply mb-20;
      }
    }
  }
}

.bold,
strong,
b {
    font-size: inherit;
    font-family: inherit;
    @apply font-aeonikbold font-medium;
}

.small-caps {
  @apply uppercase text-small tracking-small;
}
