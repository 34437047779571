.modal {
  @apply w-7/12 p-30 max-w-600;
  transition: all ease 0.2s;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: white;

  -webkit-overflow-scrolling: touch;
  width: 90%;
  @include center;
  position: fixed;

  animation: fade-out 0.2s both;

  @screen lg {
    @apply w-7/12 py-md pl-md pr-md;
  }
  &.open {
    animation: fade-in 0.2s both;
  }
  .modal__container {
    position: relative;
  }
}
