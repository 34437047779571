
@keyframes pulse-ring {
  0%, 10%{ 
    transform: scale(.22);
  } 
  80%, 100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(.8);
  }
}



.investments-card{
	box-shadow: 0px 7px 24px rgba(0, 0, 0, 0.09);

	.arrow-link{
		@apply transition-all duration-300;
	}

	&:hover{
		.arrow-link{
			margin-left: 10px;
		}
	}
} 

.investment-logo{
	box-shadow: 0px 7px 24px rgba(0, 0, 0, 0.09);
}


.scroll-sticky__navigation{

	&.navigation-red{

		ul{
			li{
				.scroll-sticky__navigation--link{	
					&.active,&:hover{ 
						&:after{
							@apply bg-red;
						}

					}
				}
			}
		}

	}
	&.navigation-magenta{

		ul{
			li{
				.scroll-sticky__navigation--link{	
					&.active,&:hover{ 
						.pulsating-circle {
							&:after,&:before {
								@apply bg-magenta; 
							}
						}

					}
				}
			}
		}

	}
	ul{
		li{
			.scroll-sticky__navigation--link{	
				@apply relative;
				transition: all 0.4s ease;

				.pulsating-circle {
				  	transition: all 0.4s ease;
					position: absolute;
					width: 21px;
					height: 21px;
					right: -15px;
					bottom: -10px;

					@screen md{
						right: -26px;
					}
				  
				  	&:before { 
					  	@apply bg-purple;
					    content: '';
					    position: relative;
					    width: 400%; 
					    height: 400%;
					    box-sizing: border-box;
					    margin-left: -153%;
    					margin-top: -150%;
					    display: none;
					    border-radius: 45px; 
					    animation: pulse-ring 3s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
					}
					&:after {
						content: '';
						transition: all 0.4s ease;
						position: absolute;
						width: 20px;
						height: 20px;
						border-radius: 100%;
						background: #ffffff;
						border: 1px solid #000000;
						display: block;
						left: 0;
						top: 0; 

					}
				  
				}

				&.active{
					@apply text-black;
					.pulsating-circle {
						&:after {
							@apply bg-purple;
						}
						&:before {
							display: block;
						}
					}
 
				}
				&:hover{
					.pulsating-circle {
						&:after {
							@apply bg-purple;
						} 
					}
 
				}

				&.active{

					& + .filter__container{
						display: block;
						opacity: 1; 
					}

				}
				
			}
		}
	}
	.filter__container{
		transition: all 0.4s ease;
		display: none;
		opacity: 0; 

		.icon{
			
			height: 15px;
			display: flex;
			align-items: center;

			&.rotate-45{
				transform: scaleY(-1);
				--transform-rotate: unset;
			}
		}
		a{
			display: flex;
			align-items: center;
			&:before{
				transition: all 0.4s ease;
				@apply border-1 border-darkgrey;
				content: "";
				margin-right: 15px;
				width: 7px;
				height: 7px;
				border-radius: 100%;
			}
		}
	}
	.js-dropdown__trigger{
		.js-scroll-to-hash-active{
			opacity: 0;
    		display: none;

    		&.active{
    			opacity: 1;
    			display: block;
    		}	
		}
	}
	.chevron-wrap {
		top: 22px;
		position: absolute;
		right: 20px;
	}
}


.filter__container{

	.icon{
		
		height: 15px; 
		display: flex;
		align-items: center;

		&.rotate-45{
			transform: scaleY(-1);
			--transform-rotate: unset;
		}
	}
	a:not(.btn){
		display: flex;
		align-items: center;
		&:hover{
			&:before{
				@apply bg-purple;
			}
		}
		&.active{
			&:before{
				@apply bg-purple;
			}
		} 
		&:before{
			@apply border-1 border-darkgrey;
			transition: all 0.4s ease;
			content: "";
			margin-right: 15px;
			width: 7px;
			height: 7px;
			border-radius: 100%;
		}
	}
}

