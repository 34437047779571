@font-face {
	font-family: 'aeonikregular-haas-unica';
} 


@font-face {
	font-family: 'aeonikregular';
	src: url('../fonts/Aeonik-Regular.woff') format('woff'),
		url('../fonts/Aeonik-Regular.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}


@font-face {
	font-family: 'aeonikbold';
	src: url('../fonts/Aeonik-Bold.woff') format('woff'),
		url('../fonts/Aeonik-Bold.woff2') format('woff2');
	font-weight: normal;
	font-style: normal;
}

