
footer {
	.nav__logo{
		svg{
			width: 150px;
			height: 50px;
		
			@screen lg{
				width: 200px;
	    		height: 80px;
			}
		}
	}
}
